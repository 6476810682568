<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="form">
				<span>
				<el-form-item label="用户名">
					<el-input v-model.trim="form.username" placeholder="输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="店名">
					<el-input v-model.trim="form.name" placeholder="输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="form.value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getList(0)" icon="el-icon-search">查询</el-button>
				</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">	
			<el-table-column prop="id" label="店铺ID" width="100">
			</el-table-column>
			<el-table-column prop="name" label="店铺名称" width="150">
			</el-table-column>
			<el-table-column prop="my_host" label="域名">
			</el-table-column>
			<el-table-column prop="create_at" label="开通时间" sortable width="180">
			</el-table-column>			
			<el-table-column label="app上传状态" width="140" align="center">
				<template slot-scope="scope">
					<span class="green" v-if="scope.row.apk_url">已上传</span>
					<span class="yellow" v-else>未上传</span>
				</template>
			</el-table-column>		
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<template v-if="scope.row.apk_url">
						<el-button type="primary" plain size="small" @click="editorInfo(scope.row)">修改</el-button>
						<el-button type="primary" plain size="small" @click="seeLink(scope.row)" >查看链接</el-button>
					</template>
					<template v-else>
						<el-button type="primary" size="small" @click="upLoadApp(scope.row.id)" >点击上传</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="查看链接" :visible.sync="dialogShow" width="600px" center>
			<p class="mb15">用户名: {{appInfo.username}}</p>
			<p class="mb15">店铺名称: {{appInfo.contact}}</p>
			<p>App下载地址: {{appInfo.apk_url}}</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">关 闭</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="appInfo.apk_url?'修改上传App':'上传APP'" :visible.sync="showUpox" width="600px" center>
			<el-form label-width="100px">
				<el-form-item label="当前地址" v-if="appInfo.apk_url">
					{{appInfo.apk_url}}
				</el-form-item>
				<el-form-item label="上传文件">
					<el-upload class="upload-demo" :action="uploadurl" :data="dataToken" :on-success="uploadurlApk" :beforeUpload="beforeAvatarUpload" :file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="输入下载地址">
					<el-input v-model="nowStoreInfo.apk_url" placeholder="输入域名" class="w300"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateStore">上 传</el-button>
				<el-button @click="showUpox = false, fileList = []">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { getStoreList,updateStoreInfo,getUploadToken } from '../../api/api';
	export default {
		data() {
			return {
				fileList: [],
				filters: {
					keyword: ''
				},
				form:{
					username:'',
					name:''
				},
				dialogShow: false,
				showUpox: false,
				options: [
					{
						value: '1',
						label: '未上传'
					},
					{
						value: '2',
						label: '已上传'
					},
					{
						value: '3',
						label: '全部'
					}
				],
				appInfo:{},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {},//上传文件需要携带的参数
				urlHead: '',
				nowStoreId: '',
				nowStoreInfo:{}
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			editorInfo(data){
				this.nowStoreId = data.id;
				this.appInfo = data;
				this.showUpox = true;
			},
			upLoadApp(id){
				this.nowStoreId = id;
				this.appInfo = {};
				this.showUpox = true
			},
			//获取上传文件的token
			// getUploadTokenM (){
			// 	getUploadToken({docType: 'apk'}).then((res) => {
			// 		this.dataToken.token = res.data.uptoken
			// 		this.dataToken.key = res.data.key
			// 		this.urlHead = 'http://'+ res.data.url + '/'
			// 	});
			// },
			async beforeAvatarUpload(file) {
				await getUploadToken({docType: 'apk'}).then((res) => {
					this.dataToken.token = res.data.uptoken
					this.dataToken.key = res.data.key
					this.urlHead = 'http://'+ res.data.url + '/'
				});
				var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
				const extension = testmsg === 'zip', extension1 = testmsg === 'rar', extension2 = testmsg === 'apk';
                if(!extension && !extension1 && !extension2) {
                    this.$message.error('上传的文件只能是zip、rar或apk格式!');
                }
                return (extension || extension1 || extension2)
            },
			uploadurlApk(response){
				this.nowStoreInfo.apk_url = this.urlHead + response.key
				// this.updateStore();
			},			
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = this.form;
				para.page = this.page;
				para.pagesize = this.pagesize;
				this.listLoading = true;
				getStoreList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//上传
			updateStore(){
				if(!this.nowStoreInfo.apk_url) return this.$message.error('请选择上传文件或输入下载地址');
				this.showUpox = false;
				this.listLoading = true;
				let para = {
					store_id: this.nowStoreId,
					apk_url: this.nowStoreInfo.apk_url
					// data_json: JSON.stringify(this.nowStoreInfo)
				}				
				updateStoreInfo(para).then((res) => {
					if(res.code == 1){
						this.getList();
						this.nowStoreInfo = {}
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			seeLink:function(data){
				this.appInfo = data;
				this.dialogShow = true
			},
			seeInfo(id) {
				this.$router.push({ name: '店铺详细信息', query: { id: id }})
			},
		},
		mounted() {
			//获取列表数据
			this.getList();
			//获取上传文件token
			// this.getUploadTokenM();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>